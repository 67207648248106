import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueNativeSock from 'vue-native-websocket'
import moment from 'moment'
import 'moment/locale/fr'

import './plugins/snotify'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player"

import App from './App.vue'
import router from './router/router'
import store from './store/store'

import config from './config'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './styles/flexbox.css'
import './styles/main.css'
import {
    iconizer,
    labelizer,
    colors,
    date,
    params,
    spa,
    sales,
    client,
    accessControl
} from 'neptune/mixins'
import Sysgate from 'neptune/sysgate'

Vue.use(VueNativeSock, (config.ServerURL.protocol === 'https:' ? 'wss://' : 'ws://') + config.ServerURL.host + '/api/ws', {
    reconnection: false,
    connectManually: true,
    format: 'json'
})

Vue.use(LottieVuePlayer)

moment.locale('fr')
Vue.config.productionTip = false

Vue.mixin(iconizer)
Vue.mixin(labelizer)
Vue.mixin(colors)
Vue.mixin(date)
Vue.mixin(params)
Vue.mixin(spa)
Vue.mixin(sales)
Vue.mixin(client)
Vue.mixin(accessControl)

Vue.prototype.$sysgate = new Sysgate(true)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
