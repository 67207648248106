
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const KEEP_ALIVE_INTERVAL = 60000

export const SYSGATE_STATE_LOADING = 'loading';
export const SYSGATE_STATE_READY = 'ready';
export const SYSGATE_STATE_DISCONNECTED = 'disconnected';

export default class Sysgate {

    constructor(autoreconnect = false, reconnectInterval = 1000, listeners = {}) {
      this.autoreconnect = autoreconnect
      this.reconnectInterval = reconnectInterval
      this.ws = null
      this.keepAliveInterval = null
      this.state = SYSGATE_STATE_DISCONNECTED
      this.callbacks = new Map()
      this.pendingRequests= new Map(),
      this.eventListeners = listeners || {} 
    }

    // events are "open", "close", "error", "message"
    on(event, handler) {
      if (!this.eventHandlers[event]){
        this.eventListeners[event] = []
      }

      this.eventListeners[event].push(handler)
    }

    off(event, handler) {
      if (!this.eventListeners[event]){
        return
      }

      this.eventListeners[event] = this.eventListeners[event].filter(h => h != handler)
    }

    onEvent(event, payload){
      if (!this.eventListeners[event]){
        return
      }

      this.eventListeners[event].forEach(handler => handler(payload))
    }

    isLoading() {
      return this.state === SYSGATE_STATE_LOADING
    }

    isReady() {
      return this.state === SYSGATE_STATE_READY
    }

    connect() {
      this.state = SYSGATE_STATE_LOADING;
      this.ws = new WebSocket('ws://localhost:8156/api/ws')

      this.ws.addEventListener('open', () => { this._onOpen() })
      this.ws.addEventListener('close', () => { this._onClose() })
      this.ws.addEventListener('error', (event) => { this._onError(event) })
      this.ws.addEventListener('message', (payload) => { this._onMessage(payload) })
    }

    _onOpen() {
      this.keepAlive()

      this.state = SYSGATE_STATE_READY;

      // re-subscribe to all events
      this.callbacks.forEach((callbacks, eventType) => {
        if (callbacks.length > 0) {
          this.send('subscribe', { Resource: eventType })
        }
      })

      this.onEvent('open')

      this.reconnectInterval = 1000

      console.warn('Sysgate connected')

      if (!this.keepAliveInterval) {
        this.keepAliveInterval = setInterval(() => { this.keepAlive() }, KEEP_ALIVE_INTERVAL)
      }
    }

    _onClose(event) {
      this.state = SYSGATE_STATE_DISCONNECTED;

      if (!this.autoreconnect){
        console.error('sysgate socket closed', event)
      }

      if (this.autoreconnect) {
        // Socket closed, try to reconnect
        clearInterval(this.keepAliveInterval)
        this.keepAliveInterval = null
        setTimeout(() => {
          try{
            this.connect()
          } catch(err){
            // ignore
          }
        }, Math.min(this.reconnectInterval), 60000)
        this.reconnectInterval += 5000
      }

      this.onEvent('close', event)
    }

    _onError(err) {
      if (!this.autoreconnect){
        console.error('sysgate socket error', err, this.state)
      }

      this.onEvent('error', err)
    }

    _onMessage(event) {
      if (!event.data) {
        console.warn('empty message')
        return
      }

      const payload = JSON.parse(event.data)

      // console.log('message received', payload)

      let pendingRequest = this.pendingRequests.get(payload.ID)
      if (pendingRequest) {
        console.log(pendingRequest)
        this.pendingRequests.delete(payload.ID)
        if (payload.Channel == "error") {
          pendingRequest.reject(payload ? payload.Data : null)
          return
        }

        pendingRequest.resolve(payload ? payload.Data : null)
      }

      let handlers = []
      // manage callbacks
      const eventCallbacks = this.callbacks.get(payload.Channel)
      if (eventCallbacks) {
        let exclusiveCallbacks = eventCallbacks.filter(cb => cb.exclusive)
        if (exclusiveCallbacks.length) {
          // execute the latest added exclusive callback
          handlers.push(exclusiveCallbacks[exclusiveCallbacks.length - 1].handler)          
        } else [
          handlers = eventCallbacks.map(cb => cb.handler)
        ]

        handlers.forEach(handler => handler(payload.Data))
      }

      // do other things
      this.onEvent('message', payload)

    }

    send(channel, frameData) {
      if (this.state !== SYSGATE_STATE_READY) {
        return new Promise((resolve, reject) => {
          reject(`send ${channel}: sysgate not ready`)
        })
      }

      const frame = {
        ID: uuidv4(),
        Channel: channel,
        Data: JSON.stringify(frameData),
      }

      // console.log('sending message', frame)

      this.ws.send(JSON.stringify(frame))

      return new Promise((resolve, reject) => {
        this.pendingRequests.set(frame.ID, { sendTimestamp: moment(), resolve, reject })
      })
    }

    subscribeEvent(eventType, callback, options) {
      let eventCallbacks = this.callbacks.get(eventType)
      if ((!eventCallbacks || !eventCallbacks.length)){
        eventCallbacks = []
      }

      eventCallbacks.push({
        handler: callback,
        exclusive: options && options.exclusive || false,
      })
      this.callbacks.set(eventType, eventCallbacks)

      if (this.state === SYSGATE_STATE_READY) {
        this.send('subscribe', {Resource: eventType})
      }
    }

    unsubscribeEvent(eventType, callback) {
      let eventCallbacks = this.callbacks.get(eventType)
      if (!eventCallbacks) {
        return
      }

      eventCallbacks = eventCallbacks.filter(cb => cb.handler != callback)
      this.callbacks.set(eventType, eventCallbacks)

      if (eventCallbacks.length == 0 && this.state === SYSGATE_STATE_READY) {
        this.send('unsubscribe', {Resource: eventType})
      }
    }

    // all helpers
    keepAlive() {
      this.send('keep-alive')
    }

    initiatePayment(data){
      return this.send('initiate-payment', data)
    }
}
