import Decimal from "decimal.js"
import clientHelpers from './client'

export default {
    getPaymentName(ID, allPaymentType) {
        const paymentType = allPaymentType.find(pt => ID === pt.ID)
        if (paymentType) {
            return paymentType.Label.FR
        }
        return ''
    },

    getSaleProductsQuantity(sale) {
        let quantity = 0

        if (sale.Lines) {
            for (const client in sale.Lines) {
                if (sale.Lines[client]) {
                    for (const line of sale.Lines[client]) {
                        quantity += parseInt(line.Amount)
                    }
                }
            }
        }
    
        return quantity;
    },

    getSaleLines(sale){
        if (!sale.Lines || !Object.keys(sale.Lines).length) {
            return []
        }

        return Object.values(sale.Lines).flat()
    },

    getSaleLine(id, sale){
        if (!sale.Lines || !Object.keys(sale.Lines).length) {
            return null
        }

        return this.getSaleLines(sale).find(line => line.ID == id)
    },

    forEachSaleLine(sale, func){
        if (!sale.Lines || !Object.keys(sale.Lines).length) {
            return
        }
        for(var clientID of Object.keys(sale.Lines)){
            if (sale.Lines[clientID]){
                for(var line of sale.Lines[clientID]){
                    func(line, clientID)
                }
            }
        }
    },

    getSaleTotalByClient(sale) {
        const out = {}

        if (!sale || !sale.Lines) {
            return out
        }

        for (let clientId in sale.Lines) {
            const lines = sale.Lines[clientId]
            let total = new Decimal(0)

            for (let i = 0; i < lines.length; ++i) {
                total = total.add(lines[i].Total)
            }

            out[clientId] = total.toFixed(2)
        }

        return out
    },

    isProductInSale(sale, productID, clientID){
        let inSale = false
        this.forEachSaleLine(sale, (line, cliID) => {
            if ((!clientID || cliID === clientID) && line.ProductID === productID){
                inSale = true
                return
            }
        })

        return inSale
    },

    getProductPriceForClient(product, client, currentPeriodID){
        if(!product.Pricings[currentPeriodID]){
            console.warn("no product pricing for current period")
            return null
        }
        let clientPricingID = client ? clientHelpers.currentClientPricingID(client, currentPeriodID) : 'DEFAULT'
        let productPricing = product.Pricings[currentPeriodID][clientPricingID] || product.Pricings[currentPeriodID]['DEFAULT']
        if (!productPricing){
            console.warn("no default pricing on product for current period")
            return null
        }

        return productPricing.Price
    },

    getSaleDiscountCodes(sale){
        return sale && sale.Lines ? Object.values(sale.Lines).map(clientLines => clientLines && clientLines.map(l => l.SpecialPrice && l.SpecialPrice.DiscountCodes ? Object.keys(l.SpecialPrice.DiscountCodes) : null)).flat().flat().concat(sale.DiscountCodes ? Object.keys(sale.DiscountCodes) : []).filter(id => !!id) : []
    },
}
