import './common'
import axios from 'axios'

export default {
    getTimeslots: (params) => axios.get('/access-control/timeslots', { params }),
    createTimeslots: (data) => axios.post('/access-control/timeslots', data),
    updateTimeslots: (data) => axios.put(`/access-control/timeslots/${data.ID}`, data),
    deleteTimeslot: (id) => axios.delete(`/access-control/timeslots/${id}`),
    getTimeslotGroup: (id) => axios.get(`/access-control/timeslots-groups/${id}`),
    getTimeslotsGroup: (params) => axios.get('/access-control/timeslots-groups', { params }),
    updateTimeslotGroup: (data) => axios.put(`/access-control/timeslots-groups/${data.GroupID}`, data),
    deleteTimeslotGroup: (id) => axios.delete(`/access-control/timeslots-groups/${id}`),
    getTimeslotTemplates: (params) => axios.get('/access-control/timeslots-templates', { params }),
    getUsedTimeslotTemplates: (params) => axios.get('/access-control/used-timeslots-templates'),
    createTimeslotTemplate: (data) => axios.post('/access-control/timeslots-templates', data),
    updateTimeslotTemplate: (data) => axios.put(`/access-control/timeslots-templates/${data.ID}`, data),
    deleteTimeslotTemplate: (id) => axios.delete(`/access-control/timeslots-templates/${id}`),
    postRefreshTimeslots: (filters) => axios.post('/access-control/timeslots-refresh', filters),
    getScan: (id) => axios.get(`/access-control/scan/${id}`),
    postScanFlash: (id, data) => axios.post(`/access-control/scan/${id}/flash`, data),
    getPenalties: (params) => axios.get('/access-control/penalties', { params }),
    recreditPenaltyPassing: (data) => axios.post('/access-control/recredit-penalty', data),
    updateZoneProfile: (data) => axios.put(`/access-control/zone-profile/${data.ID}`, data),
    getZoneProfiles: (params) => axios.get('/access-control/zone-profiles', { params }),
    deleteZoneProfile: (id) => axios.delete(`/access-control/zone-profiles/${id}`),
    addTurnstilesOnZoneProfile: (data) => axios.post(`/access-control/zone-profile/${data.ZoneProfileID}/addTurnstiles`, data),
    refreshTurnstiles: (id) => axios.post(`/access-control/zone-profile/${id}/refresh-turnstiles`),
    getTurnstilesInZoneProfileByAreaID: (id) => axios.get(`/access-control/zone-profile/turnstiles-area/${id}`),
    getAccessControlLogs: (params) => axios.get('/access-control/logs', { params })
}
