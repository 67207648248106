import subscriptionHelper from './subscriptions'
import {
    clientsGenders
} from '../ui/client'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import dateHelpers from './date'


export default {
    clientsGender(x) {
        return clientsGenders.filter(elem => elem.value == x)
    },

    clientFullname(client) {
        if (!client) {
            return ''
        }

        if (client.OrgInfo && client.OrgInfo.Name) {
            if (client.Firstname || client.Lastname) {
                return client.OrgInfo.Name + ' (' + client.Firstname + ' ' + client.Lastname + ')'
            }

            return client.OrgInfo.Name
        }

        return client.Firstname + ' ' + client.Lastname
    },

    getCards(client) {
        const cards = []
        if (!client || !client.Cards) {
            return cards;
        }

        client.Cards.forEach(el => {
            if (el.CardNumber) {
                for (let i = 0; i < el.CardNumber.length; i++) {
                    cards.push({
                        CardNumber: el.CardNumber[i],
                        AttributionDate: el.AttributionDate[i],
                        CardType: el.CardType,
                        Subscriptions: el.Subscriptions
                    })
                }
            }
        })
        return cards
    },

    clientHasCardType(client, cardType) {
        return client && client.Cards && client.Cards.some(c => c.CardType == cardType && c.CardNumber && c.CardNumber.length > 0)
    },

    clientHasCardTypeCompatibleForProduct(client, product) {
        return product.Subscription &&
            product.Subscription.CardType &&
            client &&
            client.Cards &&
            client.Cards.some(c => product.Subscription.CardType.includes(c.CardType) && c.CardNumber && c.CardNumber.length > 0)
    },


    getSubscriptions(client, products) {
        const out = []
        if (!client || !client.Cards) {
            return out;
        }

        products = products === undefined ? [] : products
        var product
        if (client.Cards) {
            client.Cards.forEach(el => {
                if (el.Subscriptions) {
                    el.Subscriptions.forEach(s => {
                        product = Array.isArray(products) ? products.find(p => p.ID === s.ProductID) : products[s.ProductID]
                        out.push(Object.assign(s, { Product: product }))
                    })
                }
            })
        }
        return out
    },

    getClientSubscription(cli, subscriptionID) {
        if (!cli || !cli.Cards) {
            return null
        }

        for (const card of cli.Cards) {
            if (card.Subscriptions) {
                for (const sub of card.Subscriptions) {
                    if (sub.ID === subscriptionID) {
                        return sub
                    }
                }
            }
        }

        return null
    },

    clientHasValidSubscriptionWithProductID(client, productID, productsMap) {
        if (!client.Cards) {
            return
        }

        return client.Cards.some(c => c.Subscriptions && c.Subscriptions.some(s => s.ProductID === productID && subscriptionHelper.isSubscriptionValid(s, productsMap)))
    },

    clientHasRequiredLevelForSlot(client, slot) {
        if (!slot.Levels || slot.Levels.length === 0) {
            return true
        }

        return client.ActivitiesLevels && client.select.ActivitiesLevels.some(l => l.ActivityID === slot.ActivityID.ID && slot.Levels.includes(l.LevelID))
    },

    isClientLevelGoodForSlot(client, slot) {
        if (!slot.Levels || slot.Levels.length === 0) {
            return true
        }

        for (let level of slot.ActivityLevels) {
            if (level.Required && !client.ActivitiesLevels || !client.ActivitiesLevels.some(l => l.ActivityID === slot.ActivityID && l.LevelID === level.ID)) {
                // Level is required and client doesn't have it
                return false
            }
        }

        return true
    },

    currentClientPricingID(client, currentPeriodID) {
        let pricingID
        // get currently active client pricing
        if (client.Pricings && client.Pricings.length > 0) {
            const currentPricingIndex = this.currentClientPricingIndex(client)
            if (currentPricingIndex >= 0) {
                return client.Pricings[currentPricingIndex].PricingID
            }
        } else if (currentPeriodID && client.Pricing && client.Pricing[currentPeriodID]) {
            return client.Pricing[currentPeriodID]
        }

        return pricingID || 'DEFAULT'
    },

    currentClientPricingIndex(client, ignoredIndex) {
        return client.Pricings.findIndex((p, idx) => (!ignoredIndex || !ignoredIndex.includes(idx)) && this.isCurrentClientPricing(p))
    },

    isCurrentClientPricing(p) {
        return (dateHelpers.isPast(p.From)) && (!p.To || dateHelpers.isFuture(p.To))
    },

    updateClientPricing(client, newPricing, currentPeriodID) {
        if (!newPricing) {
            return client;
        }

        if (!client.Pricings) {
            client.Pricings = []
        }
    
        let newClientPricings = []
        for (let p of client.Pricings) {
            if (p.PricingID === "DEFAULT") {
                // remove the default pricing
                continue
            }

            if (this.isCurrentClientPricing(p)) {
                if (dateHelpers.isToday(p.From)) {
                    // remove the current pricing if it starts today
                    continue
                }

                // set the end date of the current pricing
                p.To = moment().subtract(1, 'day').endOf('day').format()
            }

            newClientPricings.push(p)
        }

        // add the new pricing
        if (newPricing.ID !== "DEFAULT") {
            newClientPricings.push({
                From: moment().startOf('day').format(),
                To: newPricing.Validity ? dateHelpers.formatDateAPI(dateHelpers.addToDateTime(undefined, newPricing.Validity, 'months')) : null,
                PricingID: newPricing.ID,
            })
        }

        client.Pricings = newClientPricings      

        // for compatibility, set the pricingID to the current period
        if (currentPeriodID) {
            client.Pricing[currentPeriodID] = newPricing.ID
        }

        return client
    },

    getDefaultClient() {
        return {
            ID: '',
            Firstname: '',
            Lastname: '',
            ClientType: 1,
            OrgInfo: {
                Name: '',
                Type: 0,
                TVA: '',
                SIRET: '',
                RCS: '',
                NAF: ''
            },
            BirthDate: null,
            Address: {
                Address: '',
                PostalCode: '',
                City: '',
                Country: ''
            },
            Contact: {
                Phone: '',
                Email: ''
            },
            Pricing: {}
        }
    },

    assignCardToClient(client, cardType, cardNumber){
        if (!client.Cards){
            client.Cards = []
        }

        let cardIndex = client.Cards.findIndex(c => c.CardType === cardType)
        if (cardIndex === -1){
            cardIndex = client.Cards.length
            client.Cards.push({
                CardType: cardType,
                CardNumber: [],
                AttributionDate: []
            })
        }

        client.Cards[cardIndex].CardNumber.push(cardNumber)
        client.Cards[cardIndex].AttributionDate.push(dateHelpers.formatDateAPI())

        return client
    },

    dissociateCardFromClient(client, cardNumber){
        if (!client.Cards){
            return client
        }

        let cardIndex = client.Cards.findIndex(c => c.CardNumber && c.CardNumber.includes(cardNumber))
        if (cardIndex === -1){
            return client
        }

        let cardNumberIndex = client.Cards[cardIndex].CardNumber.findIndex(c => c === cardNumber)
        if (cardNumberIndex === -1){
            return client
        }

        client.Cards[cardIndex].CardNumber.splice(cardNumberIndex, 1)
        client.Cards[cardIndex].AttributionDate.splice(cardNumberIndex, 1)

        return client
    }
}
